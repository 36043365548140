<template>
  <div>
    <div class="min-h-screen" id="allOrder">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Class Schedule Overview</h1>
      </div>
      <Calendar />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ClassSchedule',
  components: {
    Calendar: () => import(/* webpackChunkName: "Calendar" */ '@/components/Calendar/Calendar'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters('common', ['digitalPlatforms', 'refreshPage'])
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading'])
  }
}
</script>
<style scoped lang="scss">
</style>
